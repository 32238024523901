import { Capacitor, CapacitorHttp, HttpHeaders, HttpResponse } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AndroidFullScreen } from '@ionic-native/android-full-screen';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import Debug from 'debug';
import get from 'lodash/get';
import { Browser } from '@capacitor/browser';

import { isAndroid, isIOS, supportsLandscape } from '~common/device';
export { getNormalizedLocationPath } from '~common/utils';

import { getStore } from 'domains/redux/functions/getStore';
import i18n from 'lib/i18n';

const debug = Debug('ovosplay:native');

type CustomFetchResponse = HttpResponse & {
  headers: Headers;
  ok: boolean;
  text: () => Promise<string>;
  json: () => Promise<object>;
  statusText: string;

  // props from fetch.Response which are not implemented
  // (why capacitor does not respond with 1:1 compliant fetch's Response type, is beyond me)
  arrayBuffer: never;
  blob: never;
  body: never; // fetch.Response.body can only be a `ReadableStream<Uint8Array>`, not just `string`
  bodyUsed: never;
  bytes: never;
  clone: never;
  formData: never;
  redirected: never;
  type: never;
};

const defaultCapacitorFetchOptions = {
  method: 'GET',
};

/**
 * This is a very simple wrapper around Capacitors native HTTP functionality
 * to make it useable interchangeably with the regular web fetch (for our use case).
 *
 * Note: Only the most used return values of Window['fetch'] are set here.
 */
const capacitorFetch: (...args: Parameters<Window['fetch']>) => Promise<CustomFetchResponse> = async (url, options = {}) => {
  const { method, headers, body } = Object.assign({}, defaultCapacitorFetchOptions, options);
  let data = body;

  if (typeof url !== 'string') {
    throw new Error('Only use capacitorFetch with (url: string, options: RequestInit)');
  }

  try {
    if (
      typeof body === 'string' &&
      headers && 'content-type' in headers &&
      headers['content-type'] === 'application/json'
    ) {
      data = JSON.parse(body);
    }

    const res = await CapacitorHttp.request({
      url,
      headers: {
        ['x-client-version']: import.meta.env.VITE_APP_VERSION,
        'x-language': i18n.language,
        ...headers,
      } as HttpHeaders,
      data,
      method: method.toUpperCase(),
      webFetchExtra: {
        credentials: 'include',
      },
    });

    /**
     * graphql-request uses headers.forEach() which we have to add to the HttpResponse's headers object.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Headers/forEach
     */
    Object.defineProperty(res.headers, 'forEach', {
      value: (handler: (value: any, key: string) => any) => {
        return Object.keys(res.headers).forEach((key) => {
          return handler(res.headers[key], key);
        });
      },
      enumerable: false,
    });

    // make "get" function available on res.headers and look for key ignoring case
    Object.defineProperty(res.headers, 'get', {
      value: (key: string) => {
        return res.headers[key];
      },
      enumerable: false,
    });

    return <CustomFetchResponse>{
      ...res,
      headers: res.headers as unknown as Headers,
      ok: res.status >= 200 && res.status <= 299,
      text: () => Promise.resolve(typeof res.data === 'object' ? JSON.stringify(res.data) : res.data),
      json: () => Promise.resolve(typeof res.data === 'object' ? res.data : JSON.parse(res.data)),
      statusText: `${res.status}`,
    };
  } catch (err) {
    console.error('An error occurred during capacitorFetch:', err);

    throw err;
  }
};

export const fetchRequest =
  !Capacitor.isNativePlatform() // use fetch if we're in non-native env
  || localStorage.getItem('useFetch') === 'true' // use fetch if `useFetch="true"` is set in localStorage
    ? (info: RequestInfo | URL, init?: RequestInit) => fetch(info, {
    ...init,
    credentials: 'include',
    headers: {
      ['x-client-version']: import.meta.env.VITE_APP_VERSION,
      'x-language': i18n.language,
      ...(init?.headers || {}),
    },
  })
    : capacitorFetch;

/**
 * Sets up various native app related functionality, such as hiding status bar,
 * configuring keyboard accessorybar and handling immersive mode on android.
 */
export function setupBasicNativeAppFunctionality() {
  if (Capacitor.isNativePlatform()) {
    if (!supportsLandscape()) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }

    // set status bar font color to white to be visible on the black notch background
    StatusBar.setStyle({ style: Style.Dark });

    if (isAndroid()) {
      StatusBar.hide();
    }

    Keyboard.addListener('keyboardDidShow', (info) => {
      // This code snippet dynamically adjusts the app's height to ensure input fields are not covered by the keyboard when it is opened.
      // It mimics the iOS behavior, which provides a better user experience by keeping the input fields visible and accessible.
      if (isAndroid()) {
        const keyboardHeight = info.keyboardHeight;
        const body = document.body;
        const html = document.documentElement;
        const slider = document.getElementById('slider');

        const viewportHeight = window.innerHeight;

        // adjust the body and html styles to accommodate the keyboard height
        body.style.paddingBottom = `${keyboardHeight}px`;
        html.style.height = `${viewportHeight - keyboardHeight}px`;

        // if a slider element exists, adjust its height using a custom property to maintain layout consistency
        if (slider) {
          slider.style.setProperty('--slider-height', `${viewportHeight - keyboardHeight}px`);
        }
      }

      const activeElement = document.activeElement;

      if (activeElement) {
        activeElement.scrollIntoView({
          behavior: 'instant',
          block: 'center', // scroll to center so the input fields at the bottom are not covered by sticky bottom elements
        });
      }
    });

    Keyboard.addListener('keyboardWillHide', () => {
      if (isAndroid()) {
        const body = document.body;
        const html = document.documentElement;
        const slider = document.getElementById('slider');

        body.style.paddingBottom = '0px';
        html.style.height = 'auto';

        if (slider) {
          slider.style.setProperty('--slider-height', `${window.innerHeight}px`);
        }
      }
    });

    if (isIOS()) {
      debug('Setting up iOS specific functionality...');

      // accessory bar is only available on iOS devices
      Keyboard.setAccessoryBarVisible({ isVisible: true });

      /**
       * Due to an issue with the graphql-ws socket connection getting
       * stuck, we have to use nosleep.js to prevent the app from sleeping.
       *
       * This keeps the ws connection alive.
       */
      // quickly disabled before release due to it displaying No Name sound on some iOS devices (didnt see it on my ipad pro)
      // import('nosleep.js').then(NoSleep => {
      //   const nosleep = new NoSleep.default();

      //   // Enable wake lock.
      //   // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
      //   document.addEventListener('click', function enableNoSleep() {
      //     document.removeEventListener('click', enableNoSleep, false);
      //     nosleep.enable();
      //   }, false);
      // });

      /**
       * ensure we open links that point to internal routes wit target="_blank"
       * inside the app on iOS devices - iOS treats links with target="_blank" as external links
       * and attempts to open them in Safari or another installed web browser,
       * even if the linked content is within the same app - which doesn't work for us
       *
       * handle absolute URLs with Browser.open
       */
      document.addEventListener('click', async (event) => {
        const targetElement = event.target as HTMLElement;
        const anchorElement = targetElement.closest('a');

        if (anchorElement) {
          const href = anchorElement.getAttribute('href') || '';

          if (anchorElement.getAttribute('target')?.startsWith('_blank') && href.startsWith('#/')) {
            event.preventDefault();
            window.location.href = href;
          }

          if (href.startsWith('https://') || href.startsWith('http://')) {
            event.preventDefault();
            try {
              await Browser.open({ url: href });
            } catch (error) {
              console.error('Error opening link:', error);
            }
          }
        }
      });
    }

    /**
    * Calling this ensures that our app is in immersive mode on android which
    * should properly hide the menubar (even after keyboard input)
    * https://github.com/mesmotronic/cordova-plugin-fullscreen
    */
    if (isAndroid() && AndroidFullScreen) {
      AndroidFullScreen.immersiveMode();
    }
  }
}

export function hideKeyboard() {
  if (document.activeElement instanceof HTMLElement && document.activeElement.nodeName === 'INPUT') {
    document.activeElement.blur();
  }
}

/**
 * Attaches ?native= with mobile app id to allow proper redirect back to app from service side
 */
export function getMobileRedirectUrl(url: string) {
  return `${url}?native=${isIOS() ? window.MOBILE_APP_IOS_APPID : window.MOBILE_APP_ANDROID_APPID}`;
}

export const removeIfFalsyOrEmpty = <T>(obj: T, keysToRemove: (keyof T)[]) => {
  for (const key of keysToRemove) {
    const val = obj[key];
    if (
      !val ||
      (Array.isArray(val) && val.length <= 0) ||
      key === '__typename'
    ) delete obj[key];
  }

  return obj;
};

/**
 * Replaces {{user.something.xyz}} with the current value from app state
 *
 * @param url - the URL in which to replace store values
 * @returns the parsed URL with the values from the app state
 */
export function parseUrl(url: string = ''): string {
  const state = getStore().getState();

  return url.replace(/{{([a-zA-Z.\-_]*)}}/gi, (_, key) => {
    const value = get(state, key);
    if (value === undefined || value === null) {
      console.warn(`No value found for key: ${key}`);
      return '';
    }
    return encodeURIComponent(String(value));
  });
}
